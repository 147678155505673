.load-offre-soins{
    position: relative;
    z-index: 1;
    text-align: center;
    color: $A;
    background-color: mix($A, #fff, 30%);
    font-weight: 700;
    cursor: pointer;
    .lib{
        line-height: carte-selection-load-height;
    }
    &.disabled{
        cursor: default;
        font-style: italic;
        color: $G3;
        background-color: #fff;
        font-weight: 400;
    }
}