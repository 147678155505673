.carte-page-content,
.carte-bottom-panel
    position: absolute
    left: 0;
    right: 0;
    bottom: 0;

.carte-page-content{
    top: top-nav-height + tabs-menu-height + selector-infos-height;
    z-index: 1;
}
.carte-bottom-panel{
    z-index: 2;
    overflow: hidden;
    transition: height default-animation-duration ease;
    display: flex;
    flex-direction: column;
    .offre-soins-container{
        flex: 1;
    }
    &.selection-not-active {
        height: carte-bottom-nav-height;
    }
    &.selection-not-loaded {
        height: carte-bottom-nav-height + carte-selection-load-height;
    }
    &.offre-soins-part-display {
        height: carte-bottom-nav-height + offre-soins-header-height + offre-soins-part-display-height + carte-selection-load-height;
    }
    &.offre-soins-full-display {
        height: 100%;
    }
}