html
    font-size 100%

body
    font-family source-sans
    set-font-size(14px)
    line-height 1.1em
    color $G1

*
    outline none
    box-sizing border-box

a
    color inherit
    text-decoration none

input, textarea, keygen, select, button
    font inherit
    border none
    background-image none
    box-shadow none

button, input, textarea
    -webkit-appearance none

button
    cursor pointer
    border none

input::-ms-clear
    display none


#app-content
    position relative
    top 0
    left 0
    right 0
    bottom 0
    display flex

.hidden
    display none !important


.center-container
    width 100%
    max-width center-content-max-width
    margin auto


@media all and (max-width center-content-max-width + left-nav-width + left-nav-width)
    .center-container
        width "calc(100% - 2 * %s)" % left-nav-width
        min-width center-content-min-width
