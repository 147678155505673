sexe-chart-image-height = 44px
sexe-chart-bare-height = 25px

.sexes-container
	position relative

	.decorative-trame
		position absolute
		top sexe-chart-image-height
		left 0
		right 0
		height sexe-chart-bare-height
		addTrame(rgba($G4, .5), white)
		z-index -1

	.center-container
		display flex
		justify-content center
		&.benchmark
			justify-content space-between


	.unite
		text-transform lowercase

	.sexe-chart
		width calc(50% - 20px)

		for bench, $color in $benchColor
			&.bench-{bench}
				color $color
				.bare
					border-color $color
					.homme
						background-color $color
					.femme
						addTrame(rgba($color, .3), white)

	.top-percent, .bare, .bottom-detail
		flex 1
		display flex
		justify-content space-between

	.top-percent
		height sexe-chart-image-height

		.percent
			font-family core-circus
			set-font-size(39px)
			line-height 1.2em
			letter-spacing -0.2em
			margin 0 10px

		.homme, .femme
			display flex
		.image
			width 37px
			height sexe-chart-image-height
		.homme .image
			background-image url("/assets/images/homme.svg")
		.femme .image
			background-image url("/assets/images/femme.svg")


	.bare
		border-style solid
		border-width 2px
		height sexe-chart-bare-height

	.bottom-detail

		.homme, .femme
			display flex
			flex-direction column
			margin 14px 0 10px 0
			set-font-size(16px)

			.lib
				set-font-size(20px)
				line-height 1.1em

		.femme
			align-items flex-end

	.sexe-inconnu
		set-font-size(11px)

.ages-container
	addTrame(rgba($G4, .5), white)
	height 370px
	border-bottom solid 2px $G4


	for bench, $color in $benchColor
		.recharts-line-chart .bench-{bench},
		.recharts-line-chart-dot .bench-{bench}
			path
				stroke $color
			circle
				fill $color
				stroke $color
	.recharts-line-chart-dot
		stroke-width 0
		fill-opacity .4

	.recharts-x-axis, .recharts-y-axis,
	.recharts-cartesian-grid
		line
			stroke $G4

	.recharts-tooltip-cursor
		stroke transparent

	.label-tooltip
		set-font-size(20px)
		font-weight 100
		margin: 0

	.ul-tooltip
		list-style none
		padding 0
		margin 0

	.tooltip
		background-color white
		padding 10px
		box-shadow 0 0 10px rgba(black, .2)
		border-radius 4px

		li
			margin 5px 0
			set-font-size(12px)
			font-weight 600

		.percent
			display block

		.unite
			text-transform uppercase

		for bench, $color in $benchColor
			.bench-{bench}
				color $color

	.recharts-reference-line
		line[stroke="grey"]
			stroke $G4

		line[stroke="red"]
			stroke rgba($A, .5)

