.container-map,
//.carte-selector,
.leaflet-container
	position absolute
	top 28px
	bottom 0
	left 0
	right 0

	.leaflet-bar
		position relative
		right 0
		height 80px

.carte-selector
	display flex
	justify-content center
	align-items center
	height 100%
	width 100%

.leaflet-bottom.leaflet-right
	left 0
	right 0
	bottom 0
	width 100%
	height 38px
	display flex
	align-items center
	background-color rgba(white, .3)

	.leaflet-control-attribution.leaflet-control
		margin-left 20px
		set-font-size(10px)
		color $G2
		background none

		a
			color $G2
