.fiche-summary
	display flex
	flex-direction column
	justify-content center
	align-items center
	height fiche-summary-height
	margin-top selector-infos-height


	&.benchmark
		flex-direction row

		.titles
			flex-direction column
			flex 2
			.subtitle
				set-font-size(22px)
				font-weight 400
				line-height 1.1em

		.title
			set-font-size(30px)
			font-weight 300
			line-height 1.1em

		.bench-etsA, .bench-etsB
			flex-direction column
			flex 1

			.letter
				display block
				width 41px
				height 41px
				background-repeat no-repeat
				margin-bottom 5px

			.unite
				margin 0
				margin-top 2px

		.bench-etsA
			order 1

			.letter
				background-image url("/assets/images/A.svg")

		.bench-etsB
			order 3

			.letter
				background-image url("/assets/images/B.svg")

		.value
			flex-direction column
			align-items center


	.titles
		order 2
		display flex
		flex-direction row
		justify-content center
		align-items center
		margin 20px
		width auto


	.title, .subtitle
		set-font-size(28px)
		font-weight 100
		margin 0 0.15em
		text-align center

	.bench-etsA, .bench-etsB
		order 3
		display flex
		flex-direction row
		align-items center

		.letter
			display none

		.value
			display flex
			set-font-size(18px)
			font-weight 100

		.unite
			margin 2px .5em 0 .5em
			font-weight 600
			text-transform uppercase
			set-font-size(12px)


	.bench-etsA
		color $A
	.bench-etsB
		color $B
