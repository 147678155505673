.list-etablissements
    .list-header
        &.cell
            height list-header-height

        .label-and-desc
            position relative
            display flex
            flex-direction column
            justify-content center

        .sort-action-container
            display flex
            flex 1
            align-self stretch
            cursor pointer

        .sort
            display flex
            flex-direction column
            align-self center
            color $G2
            margin-left 7px

            .active
                color white

            .icon
                set-font-size(14px)
                &.asc
                    margin-top 4px
                &.desc
                    margin-top -6px

        .filter-dropdown
            margin-left: -35px;
            margin-right: 5px;

    .cell
        height list-row-height
        display flex
        align-items center

    .cell-spacer
        opacity 0

    .cell-number
        text-align right

    .cell-rank
        position relative
        height list-row-height
        line-height list-row-height
        set-font-size(12px)
        font-weight 700
        text-align center
        border-left-style solid
        border-left-width 2px
        border-left-color $G4
        color $G3

        &:before
            content ""
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            z-index -1
            background-color $G5

    .header-multiline
        .label
            position absolute
            width 255px
            top 1em

        .data-desc
            justify-self flex-end
            margin-top 1em

        .sort
            margin-top 12px

    .header-pdm, .header-value
        .filter-dropdown
            margin-left -12px
            
        .sort-action-container
            justify-content: flex-end;

        &.other .label
            display none

    .label-title
        set-font-size(12px)
        font-weight 600
        text-transform uppercase

    .ReactVirtualized__Table__headerColumn:first-of-type,
    .ReactVirtualized__Table__rowColumn:first-of-type
        margin-left: 0

    .ReactVirtualized__Table__headerColumn
        position relative

    .ReactVirtualized__Table__headerRow
        position relative
        background $G4
        text-transform none
        overflow visible !important


    .ReactVirtualized__Table__row
        &.odd
            addTrame(rgba($G4, .5))

        &.bench-etsA
            addTrame(rgba($A, .25))

            .cell-rank
                border-left-color $A
                color $A
                &:before
                    background-color rgba($A, .3)

        &.bench-etsB
            addTrame(rgba($B, .25))

            .cell-rank
                border-left-color $B
                color $B
                &:before
                    background-color rgba($B, .3)