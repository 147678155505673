.carte-attractivite{
    display: flex;
}
.leaflet-container{
    width: 100%;
    background-color #DCEAF0;
    position: relative;
    z-index: 1;
}
.carte-container{
    position: absolute;
    top: carte-infos-height
    bottom: 0;
    width: 100%;
    display: flex;
}
.carte {
    position: relative;
    z-index: 1;
    flex: 1;
}
.leaflet-control-container{
    .leaflet-top.leaflet-right{
        top: 50%;
        transform: translateY(-50%);
        .leaflet-control-zoom{
            margin: 0;
            border: none;
            border-radius: 0;
        }
        .leaflet-control-zoom-in, .leaflet-control-zoom-out{
            width: 40px;
            height: 40px;
            line-height 40px;
            border: none;
            font-weight: 700;
            border-radius: 0;
            set-font-size(28px);
            color: $A;
        }
        .leaflet-control-zoom-in{
            border-bottom: solid $G4 thin;
        }
    }
}
.leaflet-div-icon{
    background-color: transparent;
    border none
    position absolute
    top 0
    bottom 0
    margin-top -48px
    margin-left -19px
    cursor pointer
    background-clip padding-box
    border-radius 50%
    svg{
        position absolute
        z-index 99
    }
    path.bench-default{
        fill: $G4;
    }
    text.bench-default{
        fill: $G2;
    }
    path.bench-etsA{
        fill: $A;
    }
    path.bench-etsB{
        fill: $B;
    }
    text.bench-etsA, text.bench-etsA{
        fill: #ffffff;
    }
}
.leaflet-div-icon-selected{
    background-color: transparent;
    border none
    position absolute
    top 0
    bottom 0
    margin-top -48px
    margin-left -19px
    background-clip padding-box
    border-radius 50%
    cursor pointer
    svg{
        position absolute
        z-index 99
    }
    path.bench-default{
        fill: $G4;
    }
    path.bench-etsA{
        fill: $A;
    }
    path.bench-etsB{
        fill: $B;
    }
    text.bench-etsA{
        fill: $A;
    }
    text.bench-etsB{
        fill: $B;
    }
}

.busted-cluster
    position absolute
    top 0
    left 0
    height auto
    width auto
    transition all .3s ease-out

.leaflet-overlay-pane{
    path.offre-soins-line{
        stroke: $G1;
        stroke-width: 1px;
    }
}

.custom-cluster-container
    width 38px
    height 38px
    position absolute
    top 0
    bottom 0
    margin-top -19px
    margin-left -19px

    .custom-cluster
        width 38px
        height 38px
        background-color white
        border-radius 50%

    span
        position absolute
        display flex
        justify-content center
        align-items center
        height 24px
        width 24px
        background-color white
        border-radius 50%
        color $G1
        set-font-size(11px)
        z-index 999
        top 7px
        left 7px

// popup
.leaflet-popup-content-wrapper
    border-radius 8px

.leaflet-popup-content
    margin 0
    width 201px !important
    height 154px

.custom-popup-container
    display flex
    justify-content center
    align-items center
    flex-direction column
    width 100%
    height 100%

    .popup-etab
        height 102px
        display flex
        justify-content space-around
        align-items center
        flex-direction column
        padding 20px 5px 10px

        .icon
            width 32px
            height 32px
            background-image: url('/assets/images/MENU-etab-ON.svg')
            background-repeat no-repeat

        .etab-lib
            set-font-size(12px)
            color $G1
            text-align center

    .popup-btn-group
        height 52px
        width 100%
        display flex
        flex-direction row
        justify-content center
        padding-bottom 1px

        .popup-btn
            width calc(50% - 1px)
            display flex
            background-color $G5
            justify-content center
            align-items center
            set-font-size(14px)
            font-weight 700
            cursor pointer

        .btn-group-A
            border-bottom-left-radius 8px
            border-right 1px solid white
            &:hover
                background-color rgba($A, .2)
            .btn-a,
            .icon-delete
                width 100%
                height 100%
                display flex
                justify-content center
                align-items center
                border-bottom-left-radius 8px
            .btn-a
                color $A
            .icon-delete
                display none
                background-color rgba($A, .2)
                color $A

        .btn-group-A.selected
            &:hover .btn-a
                display none
            &:hover .icon-delete
                display flex
            .btn-a
                background-color $A
                color white

        .btn-group-B
            border-bottom-right-radius 8px
            border-left 1px solid white
            &:hover
                background-color rgba($B, .2)
            .btn-b,
            .icon-delete
                width 100%
                height 100%
                display flex
                justify-content center
                align-items center
                border-bottom-right-radius 8px
            .btn-b
                color $B
            .icon-delete
                display none
                background-color rgba($B, .2)
                color $B

        .btn-group-B.selected
            &:hover .btn-b
                display none
            &:hover .icon-delete
                display flex
            .btn-b
                background-color $B
                color white
