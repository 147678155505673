selectorNavImg = {
            etsA: 'etab',
            appareil: 'appareil',
            priseEnCharge: 'prise',
            ghm: 'GHM',
            age: 'age',
        }



.selector-nav
    flex 1
    display flex
    flex-direction column
    justify-content: space-around;
    list-style-type: none;
    padding: inherit
    .item
        &.active .illustration:before
            content ""
            position absolute
            z-index -1
            top: -32px
            bottom: -32px
            left: 0
            right: 0
            background: linear-gradient(to bottom, rgba($G4, 0), rgba($G4, .5), rgba($G4, 0));

        .illustration
            position relative
            cursor: pointer;
            margin 6px 0

            .icon
                margin: auto;
                width: selector-nav-illustration-size
                height: selector-nav-illustration-size
                border-radius 50%
                background-color white
                background-position center
                background-repeat no-repeat

        for className, src in selectorNavImg
            .{className} .icon
                background-image url('/assets/images/MENU-' + src + '-OFF.svg')

            &.active .{className} .icon
                background-image url('/assets/images/MENU-' + src + '-ON.svg')

        .counter
            position relative
            margin: auto;
            cursor: pointer;
            width: 34px
            height: 34px
            border-radius: 50%
            text-align: center
            font-weight: 600
            set-font-size(13px);
            .icon
                display: none
            .lib
                display: block
            .icon, .lib
                line-height 34px
            &.bench-a
                background-color: $A
                color: #fff
                margin 5px auto
            &.bench-b
                background-color: $B
                color: #fff;
            &.tous
                background-color: $G4
                color: $G2
            &.empty
                opacity 0

            &.remove-enabled:hover
                .lib
                    display none
                .icon
                    display block
                &.bench-a
                    background-color: rgba($A, 0.2)
                    color: $A
                &.bench-b
                    background-color: rgba($B, 0.2)
                    color: $B

        .dev-actions-container
            position: absolute
            top:50%
            left:50%
            transform:translate(-50%, -50%)
            button
                position: absolute
                top: 0;
                transform:translate(-50%, -50%)
            .btn-add
                left: -34px
            .btn-remove
                left: 34px
