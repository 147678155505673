.user-dropdown
    width: user-dropdown-width
    cursor pointer

    &.dropdown--active
        background-color $G5
        .icon-arrow-down
            //transform scaleY(-1)
            transform rotate(-180deg)

        .dropdown__content
            position: absolute
            width: user-dropdown-width

    .dropdown__trigger
        height top-nav-height - 2px /* border */
        display flex
        align-items center

        &:hover
            background-color $G5

    .username
        flex 1

    .icon
        color: $B
        width 46px
        text-align center

    .icon-arrow-down
        color $A
        transition transform 0.3s ease

    .options
        line-height 30px
        set-font-size(12px)
        padding 0 5px
        border-top solid 1px $G4
        cursor pointer

        &.doc
            display: flex
            align-items: center
            set-font-size(14px)
            padding: 5px
            &:hover
                    background-color $G5

        &.contact
            background-color $G6
            &:hover
                background-color $G5

        &.by-heva
            color $A
            background-color white
            font-style italic
            border-bottom-left-radius: 8px
            border-bottom-right-radius: 8px
            &:hover
                            background-color $G5
            

    .dropdown__content
        background-color: white
        border solid 1px $G4

        &, li:last-child
            border-bottom-left-radius: 8px
            border-bottom-right-radius: 8px

        li
            line-height 42px
            border-top solid 1px $G4

            &.smaller
                line-height 30px
                background-color $G6
                set-font-size(12px)
                padding 0 5px

            &.by-heva
                color $A
                background-color white
                font-style italic

            &:hover
                background-color $G5

        a
            display flex
            align-items center