.carte-bottom-nav{
    position: relative;
    z-index: 2;
    display: flex;
    align-items center;
    height : carte-bottom-nav-height;
    box-shadow: 0 0 4px rgba(#000, 0.2);
    background-color: #fff;
    .tabs-menu{
        height : carte-bottom-nav-height;
        .item{
            height : carte-bottom-nav-height;
        }
        flex: 1;
        &.reverse{
            border-top: none;
        }
    }
    .switch-selection{
        width: 222px;
        height : carte-bottom-nav-height;
        display: flex;
        align-items center;
        addTrame(rgba($G4, 0.5), #fff);
        &.disabled{
            opacity: 0.4;
        }
    }
}