.carte-infos-etablissement {
    height: carte-infos-height;
    display: flex;
    align-items: center;
    .empty-col {
        flex: 1;
    }
    .carte-infos-etablissement-lib {
        flex: 2;
        height: carte-infos-height;
        background-color: rgba($G3, 0.1);
        display: flex;
        align-items: center;
        p {
            flex: 1;
            text-align: center;
            line-height: 16px;
        }
        .nom, .ville {
            font-weight: 700;
        }
        .finess {
            font-weight: 700;
            set-font-size(12px);
        }
    }
    .carte-infos-etablissement-values {
        flex: 2;
    }
    .carte-infos-etablissement-simple {
        display: flex;
        align-items: center;
        set-font-size(12px);
        font-weight: 700;
        .value, .evol {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .number {
            display: block;
            font-weight: 200;
            line-height: 24px;
            set-font-size(20px);
        }
    }
    .carte-infos-etablissement-benchmark {
        display: flex;
        align-items: center;
        .part-bench-value {
            font-family: core-circus;
            set-font-size(16px);
            margin: 0 16px;
            letter-spacing: -0.2em;
        }
        .content {
            flex: 1;
            set-font-size(12px);
            font-weight: 700;
            .part-other-bench {
                width: 100%;
                position: relative;
                height: 4px;
                margin: 4px 0;
                .part-bench {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                }
            }
            .value-evol {
                display: flex;
                .evol {
                    margin-left: auto;
                }
            }
        }
    }
    &.etsA {
        color: $A;
        .carte-infos-etablissement-lib {
            background-color: rgba($A, 0.1);
        }
        .part-other-bench {
            background-color: rgba($A, 0.1);
            .part-bench {
                background-color: $A;
            }
        }
    }
    &.etsB {
        color: $B;
        .carte-infos-etablissement-lib {
            background-color: rgba($B, 0.1);
        }
        .part-other-bench {
            background-color: rgba($B, 0.1);
            .part-bench {
                background-color: $B;
            }
        }
    }
}