.dropdown
    &.dropdown--active
        .dropdown__content
            display: block

    .dropdown__trigger
        user-select none
        cursor pointer

    .dropdown__content
        display: none
        position: absolute