#left-nav
	position: fixed
	left: 0
	top: 0
	bottom: 0
	width: left-nav-width
	background-color white
	border-right: 1px solid $G4
	display: flex
	flex-direction: column;
	z-index 10

	.logo
		width: 70px;
		margin: 13px auto;