.carte-legend
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    height: carte-legend-height;
    background-color: rgba($G5, 0.95)

.carte-concurrence-legend
    display flex
    flex-direction row
    justify-content center
    align-items center

    .legend-blocks
        display flex
        flex-direction row
        justify-content center
        align-items center
        width 50%
        height 100%
        text-transform uppercase

        .type-etab
            display flex
            justify-content center
            align-items center

            .colored-circle
                height 19px
                width 19px
                border-radius 50%

            .lib
                margin 0 12px

        for etab, $color in $concurrenceColors
            .type-etab.type-{etab}
                .colored-circle
                    background-color rgba($color, .5)
                .lib
                    color $color

        .pdm-txt
            color $G2
            margin-right 10px

        .pdm-percent
            display flex
            flex-direction row
            align-items center
            justify-content center
            position relative
            .percent-block
                width 50px
                .percent-number
                    color $G2
                    height 12px
                    font-weight 700
                    set-font-size(12px)
                .percent-bar
                    width 100%
                    height 6px
                    border-bottom 1px solid $G2
                    border-left 1px solid $G2

                &:last-child .percent-bar
                    border-bottom none

        .pdm-arc
            position absolute
            left 0
            bottom 0

            for etab, $color in $concurrenceColors
                .arc.type-{etab}
                    border-color $color
                    &:after
                        background-color $color

                .arc.type-{etab}.selected
                    &:after
                        border 9px solid rgba($color, .4)
                        right -13px
                        top -13px
            .arc
                position absolute
                left 0
                border 2px dashed
                border-top none
                &:after
                    position absolute
                    right -4px
                    top -3.5px
                    content ''
                    width 6px
                    height 6px
                    border-radius 50%
                    background-clip: padding-box;

            .legend-popup
                display flex
                justify-content center
                align-items center
                position absolute
                top 20px
                width 110px
                height 50px
                border-radius 3px
                background-color white
                box-shadow 0 0 4px rgba(black, .2)
                z-index 30
                &:after
                    content ''
                    position absolute
                    width 0
                    height 0
                    top -8px
                    border-style solid
                    border-width 0 8px 8px 8px
                    border-color transparent transparent white transparent

            for etab, $color in $concurrenceColors
                .legend-popup.type-{etab}
                    color $color
