#content-block
	position fixed
	top 0
	bottom 0
	right 0
	transition left default-animation-duration ease

	&.fiche-page-content,
	&.error-page-content,
	&.carte-page-content
		left left-nav-width

	&.selector-page-content
		left (left-nav-width + selector-block-width)

	.main-content
		position fixed
		left 0
		right 0
		top top-nav-height + tabs-menu-height
		bottom 0