@import "variables"

@font-face
    font-family 'unicancer'
    src url(icomoon-font-path + '/unicancer.eot?1cudao')
    src url(icomoon-font-path + '/unicancer.eot?1cudao#iefix') format('embedded-opentype'), url(icomoon-font-path + '/unicancer.ttf?1cudao') format('truetype'), url(icomoon-font-path + '/unicancer.woff?1cudao') format('woff'), url(icomoon-font-path + '/unicancer.svg?1cudao#unicancer') format('svg')
    font-weight normal
    font-style normal

@import url('//fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,700,700i');
//@import url('//db.onlinewebfonts.com/c/179d89a882a98f336dff2c59f73ac8e9?family=CoreCircus2DDoubleW00-Rg');


@font-face {
    font-family: 'CoreCircus2DDoubleW00-Rg';
    src: url('/assets/fonts/179d89a882a98f336dff2c59f73ac8e9.eot');
    src: url('/assets/fonts/179d89a882a98f336dff2c59f73ac8e9.eot?#iefix') format('embedded-opentype'),
            url('/assets/fonts/179d89a882a98f336dff2c59f73ac8e9.woff2') format('woff2'),
            url('/assets/fonts/179d89a882a98f336dff2c59f73ac8e9.woff') format('woff'),
            url('/assets/fonts/179d89a882a98f336dff2c59f73ac8e9.ttf') format('truetype'),
            url('/assets/fonts/179d89a882a98f336dff2c59f73ac8e9.svg#corecircus2ddoublew00-rgRg') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"]
    font-family 'unicancer' !important
    speak none
    font-size 23px
    font-style normal
    font-weight normal
    font-variant normal
    text-transform none
    line-height 1

    /* Better Font Rendering =========== */
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale

.icon-filter
  &:before
    content icon-filter
.icon-verrou-close
  &:before
    content icon-verrou-close
.icon-verrou-open
  &:before
    content icon-verrou-open
.icon-check
  &:before
    content icon-check
.icon-arrow-down
  &:before
    content icon-arrow-down
.icon-arrow-up
  &:before
    content icon-arrow-up
.icon-delete
  &:before
    content icon-delete
.icon-edit
  &:before
    content icon-edit
.icon-export
  &:before
    content icon-export
.icon-help
  &:before
    content icon-help
.icon-info
  &:before
    content icon-info
.icon-moins
  &:before
    content icon-moins
.icon-plus
  &:before
    content icon-plus
.icon-search
  &:before
    content icon-search
.icon-tooltip
  &:before
    content icon-tooltip
.icon-user
  &:before
    content icon-user
