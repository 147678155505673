.switch-carte-synchro{
    position: relative;
    z-index: 2;
    width: 2px;
    .action, .action .icon{
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .action{
        top: 70%;
        width: 50px;
        height: 50px;
        background-color: #fff;
        border-radius: 25px;
        cursor: pointer;
        color: $G3;
        .icon{
            top: 50%;
            set-font-size(20px);
        }
        &.active{
            color: $A;
        }
    }
}