.carte-attarctivite-legend {
    .top-legend, .bottom-legend {
        display: flex;
        align-items: center;
    }
    .top-legend {
        height: carte-legend-height - carte-attractivite-legend-bottom-height;
        set-font-size(11px);
        font-weight: 700;
    }
    .top-legend-content {
        display: flex;
        align-items: center;
        .align-right {
            margin-left: auto;
            margin-right: 10px;
        }
        &:first-child {
            .align-left {
                margin-left: 10px;
            }
        }
    }
    .bottom-legend-content {
        height: carte-attractivite-legend-bottom-height;
    }
    .legend-content {
        flex: 1;
    }
}

for name, color in $tileColor-etsA
    .carte-attarctivite-legend.bench-etsA
        .class-{name}
            color: color
        .bottom-legend
            .class-{name}
                background-color: color

for name, color in $tileColor-etsB
    .carte-attarctivite-legend.bench-etsB
        .class-{name}
            color: color
        .bottom-legend
            .class-{name}
                background-color: color