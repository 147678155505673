.carte-infos-benchmark{
    display: flex;
    height: carte-infos-height;
    align-items: center;
    .col{
        flex: 1;
        &:first-child{
            height: carte-infos-inner-height;
        }
        &:last-child{
            flex: 3;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .align-right{
        text-align: right;
    }
    .bench-etsA{
        color: $A;
    }
    .bench-etsB{
        color: $B;
    }
    .bench-title{
        width 80px
        font-family: core-circus;
        margin: 0 14px;
        .bench-type{
            set-font-size(39px);
        }
        .bench-percent{
            set-font-size(18px);
            line-height 1.2em
            letter-spacing -0.2em
        }
    }
    .carte-infos-benchmark-container{
        width: 100%;
        max-width: carte-infos-benchmark-content-max-width;
        display: flex;
        align-items: center;
    }
    .carte-infos-benchmark-content{
        display: flex;
        flex-direction: column;
        flex: 1;
        .row{
            display: flex;
            > :first-child, > :last-child{
                display: block;
                flex: 1;
            }
        }
        .part-bench-etsB{
            position: relative;
            background-color: $B;
            height: 4px;
            width: 100%;
            margin: 2px 0;
            .part-bench-etsA{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                background-color: $A;
            }
        }
    }
    .carte-infos-benchmark-evolution:not(.active){
        color: $G3;
    }
}
