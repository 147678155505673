.benchmark-btn
    display flex

    .btn
        display flex
        align-items center
        justify-content center
        width 39px
        text-align center
        min-height 39px
        height 100%
        background-color $G5
        font-weight 700
        cursor pointer

        &.selected
            color white
        &:hover
            color white

    .btn-etsA
        color $A

        &.selected
            background-color $A
        &:hover
            background-color rgba($A, .6)

    .btn-etsB
        color $B

        &.selected
            background-color $B
        &:hover
            background-color rgba($B, .6)
