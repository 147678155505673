.carte-infos{
    border-top: solid thin $G4;
    set-font-size(12px);
    text-transform: uppercase;
    .lib-geo{
        font-weight: 700;
        set-font-size(14px);
    }
    .col{
        flex: 1;
        border-right: solid 1px $G4;
        &:first-child{
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }
        &:last-child{
            border-right: none;
        }
    }
}