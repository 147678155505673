checkbox-btn-size = 20px;
checkbox-btn-inner-size = 12px;
radio-btn-size = 24px;

.checkbox-btn {
    position: relative;
    height checkbox-btn-size;
    width checkbox-btn-size;
    border: solid $G4 thin;
    background-color: #fff;
    user-select none
    .selected-zone {
        display: block
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform ease default-animation-duration;
        color: $D4;
        set-font-size(20px);
    }
    &.radio {
        height radio-btn-size;
        width radio-btn-size;
        border-radius: 9999px;
        .selected-zone {
            border-radius: 9999px;
            background-color: $D4;
            height checkbox-btn-inner-size;
            width checkbox-btn-inner-size;
            line-height checkbox-btn-inner-size;
        }

    }
    &.checked {
        .selected-zone {
            transform: translate(-50%, -50%) scale(1);
        }
    }
}

.checkbox-group
    width 100%
    display flex
    padding 10px 0

    .checkbox-btn
        flex 1
        height checkbox-btn-size * 2
        line-height checkbox-btn-size * 2
        width auto
        margin-right: 0 !important
        text-align center
        cursor pointer

        &:not(:first-child)
            border-left-width 0

        &:first-child
            border-top-left-radius 3px
            border-bottom-left-radius 3px

        &:last-child
            border-top-right-radius 3px
            border-bottom-right-radius 3px

        &.checked {
            border-color $D4
            background-color $D4
            color white
            font-weight 600
        }

        &:hover {
            addTrame(rgba($G1, .15))
        }

        .selected-zone
            display none