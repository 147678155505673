.selector-title
	display flex
	align-items center
	width 100%
	height 40px

	.title-content
		display flex
		align-items center
		padding-left 15px
		background-color $B
		width calc(100% - 39px)
		height 100%

		span
			font-family: 'Source Sans Pro', sans-serif;
			font-weight 700
			color white
			text-transform uppercase

		.slash
			padding 0 5px

	.title-btn
		display flex
		align-items center
		justify-content center
		margin-left: auto;
		width 39px
		height 40px
		background-color rgba($B, .95)
		color white
		cursor pointer
		.icon-plus,
		.icon-moins
			set-font-size(15px)
