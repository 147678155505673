circle-size = 400px
circles-count = 5

dot-size = 10px
dots-count = 10

.dots-loader
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    width circle-size
    height circle-size

    .circle-scale
        for i in (1..circles-count)
            &:nth-of-type({i})
                animation-delay i + 0s
                transform scale((10 - i) / 10)
        animation-duration: 5s;
        animation-name: scaleUpDown;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: linear;

    .circle-rotation
        for i in (1..circles-count)
            &:nth-of-type({i})
                animation-delay i + 0s
        animation-duration: 5s;
        animation-name: rotation;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-timing-function: linear;

    .dot
        background-color black
        display block
        position absolute
        top 50%
        left 50%
        width dot-size
        height dot-size
        margin -(dot-size / 2)

        angle = (360 / dots-count)
        rot = 0
        for i in (1..dots-count)
            &:nth-of-type({i})
                transform rotate(rot * 1deg) translate(circle-size / 2) rotate(rot * -1deg)

            rot = rot + angle


@keyframes scaleUpDown
    from
        transform scale(1)
    to
        transform scale((10 - circles-count) / 10)


@keyframes rotation
    from
        transform rotate(0deg)
    to
        transform rotate(360deg)