top-nav-border-size = 2px

.selector-search
	height: top-nav-height
	border-bottom top-nav-border-size solid $A
	display flex
	justify-content center
	align-items stretch

	.icon-search
		color $A
		width top-nav-height
		display flex
		align-items center
		justify-content center

	.input-search
		flex 1

		input
			height top-nav-height - top-nav-border-size
			width 100%

	.delete-search-btn
		color $A
		width 30px
		display flex
		align-items center
		justify-content center
		cursor pointer

		.icon-plus
			transform rotate(45deg)
			font-size 20px
