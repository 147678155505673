#selector-block
	position: fixed;
	left: left-nav-width
	top: 0
	bottom: 0
	width: selector-block-width
	background-color white
	display: flex
	flex-direction: column
	z-index 5
	transition: width default-animation-duration ease;

	&.ghm
		width: selector-block-ghm-width


	&.display-enter {
		transform translateX(- selector-block-width)
	}

	&.display-enter.display-enter-active {
		transform translateX(0)
		transition: transform default-animation-duration ease;
	}

	&.display-leave {
		transform translateX(0)
	}

	&.display-leave.display-leave-active {
		transform translateX(- selector-block-width)
		transition: transform default-animation-duration ease;
	}

	.selector-block-content
		height 100%
		display flex
		flex-direction column-reverse

		.list-container
			flex 1
