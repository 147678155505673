.selecteur-actions
    position absolute
    bottom 100px
    left 50%
    transform translate(-50%, 0)
    z-index 9
    button
        padding: 16px 32px;
        margin 10px
        set-font-size(15px)
        border-radius 25px;

    .submit
        background-color $A
        color #ffffff

    .cancel
        background-color #ffffff
        color $A

    .info
        background-color rgba(white, .8)
        color $G3
        cursor default