.selector-item-main, .selector-item-last
	width 100%

	.selector-item
		display flex

		.selector-item-clickable
			display flex
			overflow hidden

.selector-item-main
	min-height 55px

	.selector-item
		border-bottom 1px solid $G4

	.selector-item-clickable
		flex 1
		display flex
		width calc( 100% - 39px)
		cursor pointer
		&:hover
			addTrame(rgba($G4, .5), white)

	.selector-item-clickable.full
		width 100%

	.accordeon-btn
		width 56px
		height 55px
		display flex
		justify-content center
		align-items center
		color $A
		transition transform default-animation-duration ease

	.item-content
		flex 1
		width calc(100% - 56px)
		height 55px
		display flex
		justify-content center
		flex-direction column
		overflow hidden
		text-overflow ellipsis

		.item-lib
			font-weight 400
			padding 2px 6px

			white-space nowrap
			text-overflow ellipsis
			overflow hidden

		.item-count
			set-font-size(12px)
			font-weight 700
			color $G3
			.count
				display inline-block
				margin-right 20px
				padding: 4px 6px;
				border-radius 9999px
			.count.active
				color $A
				background-color $G5
			.count.etsB.active
				color $B
				background-color $G5

// Parent ouvert
.selector-item-main.active>.selector-item
	addTrame($G4, white)
	&:hover
		addTrame($G4, white)
	.item-content .item-count .count,
	.item-content .item-count .count.active
		background-color white
	.accordeon-btn
		transform rotate(180deg)
	.add-btn
		&:hover
			background-color rgba($A, .4)
			.icon-plus
				color white

// Enfants fermés du parent
.accordeon-content .selector-item-main>.selector-item
	background-color $G6

	.item-count span[class*='count']
		background-color transparent
	.item-count .count.active
		background-color rgba($G4, .5)

.accordeon-content .selector-item-main .selector-item-clickable:hover
	addTrame(rgba($G4, .5), $G6)

// Enfants ouverts du parent
.accordeon-content .selector-item-main.active>.selector-item
	addTrame($G4, $G5)

// Derniers enfants
.accordeon-content
	width 100%
	display flex
	flex-direction column

	.selector-item-last
		.selector-item-clickable
			display flex
			align-items center
			&:hover
				background #fff

			.item-content
				width 90%
				height 39px
				//padding 15px

		.selector-item-clickable.has-benchmark
			width calc(100% - 78px)


// Item sans parent
.selector-item-last
	min-height 39px
	.selector-item
		align-items center

		.selector-item-clickable
			display flex
			align-items center

			.item-content
				width 100%
				height 39px
				display flex
				justify-content center
				flex-direction column
				overflow hidden
				text-overflow ellipsis

				.item-lib
					height 20px
					line-height 20px
					color $G1
					padding 0 12px
		.add-btn
			height 39px

.list-container
	.add-btn
		display flex
		align-items center
		justify-content center
		margin-left: auto;
		width 39px
		height 55px
		background-color $G5
		cursor pointer
		.icon-plus,
		.icon-moins
			set-font-size(15px)
			color $A
		&:hover
			background-color rgba($A, .6)
			.icon-plus,
			.icon-moins
				color white
	.add-btn.selected
		background-color $A
		div
			color white
