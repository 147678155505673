// Appareils + Prise en charge
centerColWidth = 256px
noBenchColWidth = 640px
benchColWidth = 320px

.fiche-container
    position absolute
    top top-nav-height + tabs-menu-height + selector-infos-height + fiche-summary-height
    bottom 0
    left 0
    right 0
    overflow-x hidden
    overflow-y auto

.fiche-accordeon
    height auto

    &.active
        .accordeon-content
            max-height 500px
            margin-top 11px

        .accordeon-btn
            transform rotate(180deg)

    .accordeon-bg
        addTrame(rgba($G4, .5), white)
        height 30px
        width 100%
    .accordeon-container
        width 896px
        height 30px
        display flex
        align-items center
        justify-content center
        margin 0 auto

    .accordeon-content
        max-height 0
        overflow-y hidden
        display flex
        justify-content center
        align-items center
        transition max-height default-animation-duration ease

        .accordeon-container
            height 10px
            background none

    .fiche-col-lib
        width centerColWidth
        height 100%
        display flex
        align-items center
        justify-content space-between
        cursor pointer
        padding 0 8px

        .accordeon-btn
            color $A
            width 30px
            height 30px
            display flex
            justify-content center
            align-items center
            font-size 25px
            transition transform default-animation-duration ease

        .lib
            text-align center

        .item-count
            height 20px
            min-width 40px
            display flex
            align-items center
            justify-content center
            background-color $A
            color white
            border-radius 10px
            set-font-size(12)
            font-weight 700

    .fiche-col-barChart
        display flex
        align-items center
        justify-content flex-start
        width noBenchColWidth
        height 100%
        .barChart
            max-width 545px
            height 100%
            width 4px
            transition width default-animation-duration ease-out

        .barChart-info
            display flex
            justify-content center
            flex-direction column
            width 95px
            height 100%
            color $A
            set-font-size(14px)
            padding-left 10px
            font-weight 700
            set-font-size(14)

    .fiche-col-barChart.benchmark
        width benchColWidth
    .fiche-col-barChart.left
        justify-content flex-end

        .barChart-info
            align-items flex-end
            padding-right 10px
            padding-left 0

.fiche-accordeon.last-child
    width auto
    height 22px
    margin-bottom 0px
    margin-top 14px
    display flex
    align-items center

    .fiche-col-lib
        padding-left 38px
        justify-content center

    .barChart-info
        display flex
        flex-direction row
        justify-content flex-start
        align-items center

        .percent
            padding 0px 5px

    .left .barChart-info
        justify-content flex-end

.selected
    .fiche-col-lib
        height 22px
        addTrame(rgba($A, .25), white)
        color $A

    .barChart-bg
        min-width 4px
        position relative
        top -6px
        z-index -1
        height 22px
        addTrame(rgba($A, .25), white)

    .left .barChart-bg
        left -4px
