#top-nav
    position relative
    z-index 3
    height top-nav-height
    border-bottom solid 2px $G4
    display flex
    align-items stretch

    .filters
        flex 1
        display flex
        align-items stretch
        justify-content space-around

    .tabs-menu
        height top-nav-height
        set-font-size(12px)

        .item
            height top-nav-height
            margin 0 8px

    .switch-data-type
        text-transform uppercase

    .user-dropdown
        flex-order 100
        margin 0 25px

#filter-dropdown
  position: relative


#dropdown-menu 
  position: absolute
  top: 100%
  left: 0


#top-nav, #list-etablissements-resete-filter
    .btn
        display flex
        align-items center
        height 38px
        align-self center
        set-font-size(11px)
        font-weight 600
        text-transform uppercase
        background-color $G5
        border solid thin $G3
        padding 0 6px
        border-radius 9999px
        cursor pointer
        transition background-color 80ms ease

        .lib
            padding 0 8px
        	color: $G1

        .icon
            set-font-size(20px)

        &:hover
            background-color $G4

        &.filter-btn
            .icon
                background-color $G2
                border-radius 9999px
                text-align center
                line-height 26px
                width 26px
                color #fff
                transition background-color default-effet-duration linear

    .export-btn
        display flex
        align-items center

        .icon
            color $B
            margin-right 12px

        .btn
            border-radius 0
            border-left-width 0

            &:first-of-type
                border-top-left-radius 9999px
                border-bottom-left-radius 9999px
                border-left-width thin

            &:last-of-type
                border-top-right-radius 9999px
                border-bottom-right-radius 9999px
