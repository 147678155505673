.fiche-total-selection
	height 49px
	width 100%
	margin-bottom 49px
	display flex
	align-items center
	justify-content center

	.accordeon-bg,
	.accordeon-container
		height 49px

	div:not(.left).benchmark
		.barChart
			background-color $B
			transition width default-animation-duration ease-out

		.barChart-info
			color $B

	.fiche-col-lib .lib
		width 100%
		set-font-size(22px)
		font-weight 400

	.barChart
		background-color $A
		transition width default-animation-duration ease-out
