.switch-selection{
    display: flex;
    align-items: center;
    justify-content: center;
    > .lib{
        margin-right: 16px;
        text-transform uppercase;
        font-weight: 700;

    }
}