.container-selector-info
    addTrame(rgba($G6, .9), rgba(white, .4))
    transition left default-animation-duration ease
    &.next-to-ghm
        left selector-block-ghm-width - selector-block-width

.selector-info
    position relative
    max-height 40vh

    &.active
        overflow-x hidden
        overflow-y auto
        
        .scroll-container
            flex-flow column nowrap

        .selector-info-group
            justify-content center
            padding 5px 0

            > .separator
                display none
        .selector-info-item
            .lib
                max-width 1200px

    .scroll-container
        display flex
        flex-wrap wrap
        justify-content center
        padding 10px 0

.selector-info-group
    flex-shrink 0
    display flex
    flex-wrap wrap

    > .separator
        margin 0 14px
        width 1px
        height 14px
        background-color $G1

    &:first-child > .separator
        display none

    &.etsA .prefix, &.etsB .prefix
        text-transform uppercase
        font-weight 700

        .separator
            padding 0 5px

    &.etsA .prefix
        color $A

    &.etsB .prefix
        color $B

.selector-info-item
    display flex

    &:not(:last-child):after
        content "-"
        padding 0 5px

    .lib
        max-width 280px
        text-overflow ellipsis
        overflow hidden
        white-space nowrap

    .reste
        padding-left .5em


.selector-liste-btn
    position absolute
    right 25px
    top 5px
    width 46px
    transition transform default-animation-duration ease
    color $A
    text-align center
    cursor pointer
    &.active
        transform rotate(180deg)

.container-map
    position relative

.selector-section-selecteur
    position absolute
    top top-nav-height
    left 0
    right 0
    z-index 2

.selector-section-fiche, .selector-section-carte, .selector-section-error
    position absolute
    left 0
    right 0
    z-index 2

.selector-section-fiche, .selector-section-carte
    top top-nav-height + tabs-menu-height

.selector-section-error
    top top-nav-height
