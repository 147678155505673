code-racine-width = 92px
bench-header-width = 133px
bench-header-letter-width = 27px
bench-header-ghs-width = 90px
detail-arrow-size = 7px
detail-vertical-margin = 6px
detail-line-height = 50px

.list-racines
    .racine-background
        addTrame(rgba($G4, .5))
        margin-bottom 30px

        &.selected
            addTrame(rgba($G4, 1))

    .racine
        display flex

        .code-racine
            display flex
            flex-direction column
            justify-content center
            align-items center
            width code-racine-width
            margin-right 5px
            background-color $G4

            .part-0
                set-font-size(20px)
                font-weight 100

            .part-1
                set-font-size(14px)
                font-weight 700
                text-transform uppercase

    .infos-racine
        flex 1
        display flex
        flex-direction column

        &.benchmark .detail-ghm
            min-height 5px

        .bench-etsA, .bench-etsB, .bench-fr
            display flex

        .bench-header
            display flex
            border-style solid
            border-width 1px
            background-color white
            font-weight 700

            &.bordered
                width bench-header-width
                margin-right 5px

            .letter, .values
                display flex
                align-items center

            .letter
                flex-basis bench-header-letter-width
                flex-shrink 0
                justify-content center
                text-transform uppercase

            .values
                flex 1
                flex-wrap wrap
                justify-content space-between
                padding 0 7px

                &.multiline
                    flex-direction column
                    justify-content center
                    align-items flex-end
                &.smaller
                    set-font-size(12px)

    .list-ghm
        position relative
        z-index 2
        flex 1
        display flex

        .ghm
            position relative
            flex-basis 20px
            display flex
            align-items center
            justify-content center
            color white
            cursor: pointer

            &.selected:after
                position absolute
                content ""
                width 0
                height 0
                left: 50%
                transform translateX(-50%)
                border-style solid
                border-width detail-arrow-size

    .bench-etsA .list-ghm .ghm.selected:after
        bottom - (detail-arrow-size + detail-vertical-margin + 2)

    .bench-etsB, .bench-fr
        .list-ghm .ghm.selected:after
            top - (detail-arrow-size + detail-vertical-margin + 2)

    .detail-ghm
        position relative
        z-index 1

        &.open
            margin (detail-arrow-size + detail-vertical-margin) 0
            border-style solid
            border-width 2px 0 2px 0

        .infos
            display flex
            align-items center
            padding 13px
            background-color white

            .code
                font-weight 700

            .lib
                flex 1
                margin-left 3px

            .close-btn
                cursor pointer
                transform rotate(45deg)
                color $A


        .tables
            display flex

        .data-benchmark, .data-ghs
            flex 1
            .header
                text-transform uppercase

            .row
                flex 1
                height detail-line-height
                display flex
                align-items center
                justify-content space-around

                &:nth-child(even)
                    background-color rgba(white, 1)
                &:nth-child(odd)
                    background-color rgba(white, .5)

        .data-benchmark
            .header
                padding-left bench-header-letter-width + 1
                background-color rgba(white, .5)

            .selection, .total
                flex 1
                justify-content center

            .selection
                display flex

                .number, .percent
                    flex 1

                .number
                    padding-right 5px
                    text-align right

                .percent
                    padding-left 5px
                    text-align left

            .total
                display flex

                .number
                    display inline-block
                    width 100px
                    text-align right

            .bench
                font-weight 700

            .bench-header
                flex-basis bench-header-letter-width
                border-width 0 0 0 1px
                height detail-line-height

        .data-ghs
            background-color rgba($G5, .5)
            .header
                padding-left bench-header-ghs-width + 1

            .ghs-header
                flex-basis bench-header-ghs-width
                height detail-line-height
                padding 0 5px
                border-left solid 1px $G2
                background-color $G5
                display flex
                align-items center

            .tarif
                flex 1
                text-align center


    for bench, $color in $benchColor
        .bench-{bench}
            .bench-header
                border-color $color
                color $color
                .letter
                    background-color rgba($color, .3)
        .data-benchmark .bench-{bench}
            color $color

    for severite, $color in $ghmColors
        .ghm.severite-{severite}
            background-color $color

            &.selected
                addTrame(mix($color, white, 50%), white)
                border solid 1px $color
                color $color

                &:after
                    border-color transparent transparent $color transparent

        .bench-etsB, .bench-fr
            .ghm.severite-{severite}
                background-color mix($color, white, 60%)

                &.selected:after
                    border-color $color transparent transparent transparent

        .detail-ghm.severite-{severite}
            border-color $color
            .code
                color $color