.carte-infos-simple{
    display: flex;
    height: carte-infos-height;
    align-items: flex-start;
    padding-top : calcl-middle(carte-infos-height, carte-infos-inner-height);
    .col{
        padding-left: 3%;
        height: carte-infos-inner-height;
    }
    .carte-infos-simple-annee{
        .annee{
            font-weight: bold;
        }
        .value, .evolution{
            color: $G3;
        }
        .number{
            set-font-size(20px);
        }
        &.active{
            .value, .evolution, .annee{
                color: $A;
            }
        }
    }
}