.filter-dropdown
    position relative


    .icon
        color white
        set-font-size(20px)
        
    .row
            line-height list-row-height
            padding: 0 10px;
            font-weight 200
            
            &:first-child
                border-top-left-radius 3px
                border-top-right-radius 3px

            &:last-child
                border-bottom-left-radius 3px
                border-bottom-right-radius 3px

            &:not(:first-child)
                border-top solid 1px $G4

            &.active {
                background-color $D4
                color white
                font-weight 600
            }

            &.item
                cursor pointer
                &:hover
                    addTrame(rgba($G1, .15))

    .radioItem
        display: flex
        align-items: center
        

        .radio
            margin-right: 8px

    .choices-list
        list-style-type: none
        padding: 0
        margin: 0
    
    .dropdown-menu 
        position: absolute
        top: 150%
        left: 0
        background-color: white
        border-radius: 4px
        box-shadow: 0 0 4px rgba(black, .2)
        z-index: 1
        list-style-type: none
        padding: 0
        min-width 200px
        max-width 350px
        overflow-x hidden
        overflow-y auto
        max-height 450px

        .zone
            min-width 330px
            border-radius: 4px
            box-shadow: 0 0 4px rgba(black, .2)
        
        &:before
            content ""
            position absolute
            top: -16px
            left: 5px
            width 0
            height 0
            border-style solid
            border-color transparent transparent white transparent
            border-width 8px

    &.dropdown--active
        .dropdown__trigger
            background-color $G1

            &.dropdown-orange
                background-color $A

        .dropdown__content
            min-width 200px
            max-width 350px
            z-index 1


    .dropdown__trigger
        width 26px
        height 26px
        border-radius 9999px
        display flex
        align-items center
        justify-content center
        background-color $G3
        transition background-color default-effet-duration linear

        &.dropdown-orange
            background-color $A


        &:hover
            background-color $G2

            &.dropdown-orange
                background-color $A

    .form-content {
            min-width: 330px;
            .row {
                display: flex;
                align-items: center;

                .checkbox-btn {
                    margin-right: 8px;
                }

                &.disabled {
                    color: $G3;
                    cursor: default;
                }

                &.subtitle, &.actions {
                    &:hover {
                        cursor default
                    }
                }

                &.subtitle {
                    line-height: 32px;
                    background-color: $G4;
                    font-weight: 700;
                }
                &.actions {
                    justify-content: center;
                    background-color: $G4;
                    height: 48px;

                    button {
                        display: block
                        height: 32px;
                        line-height 32px;
                        background-color: #fff;
                        color: $A;
                        padding: 0 30px;
                        font-weight 500;
                        border-radius: 9999px;
                        &:hover {
                            addTrame($G5);
                        }
                    }
                }
            }
        }
        
    .dropdown__content
        top list-header-height - 20px
        left 0
        background-color white
        border-radius 4px
        box-shadow 0 0 4px rgba(black, .2)
        font-weight 300

        // &:before
        //     content ""
        //     position absolute
        //     top: -16px
        //     left: 5px
        //     width 0
        //     height 0
        //     border-style solid
        //     border-color transparent transparent white transparent
        //     border-width 8px

        .tabs-menu
            background linear-gradient(to bottom, white, $G5)
            .item
                margin 0
                text-transform uppercase
                text-align center
                set-font-size(13px)

        .choices-list
            overflow-x hidden
            overflow-y auto
            max-height 450px

        

    &.data-filter
        display: flex;
        align-items: center;
        .dropdown__trigger
            width: auto;

        .dropdown__content
            top list-header-height - 5px;
            color: $G1;

        &.dropdown--active .icon
            background-color $G1 !important
