.prise-en-charge-list
	.fiche-accordeon
		display flex
		justify-content center
		align-items center
		height 30px

	.fiche-col-lib
		justify-content center
		text-transform uppercase
		cursor default

		div:nth-child(2)
			width 0px!important

	.selected .fiche-col-lib
		height 30px

		.lib
			font-weight 700
for name, $color in $pecColors
	.prise-en-charge-list
		.chart-color{name}
			.barChart
				background-color $color
			.barChart-info
				color $color!important
