switch-height = 40px;
switch-width = 75px;
switch-mobil-size = 32px;
switch-h-padding = 4px;
.switch{
    height: switch-height;
    width: switch-width;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color : $G6;
    border: solid $G4 thin;
    position: relative;
    cursor: pointer;
    .lib{
        text-transform: uppercase;
        font-weight: 700;
        width: (switch-width - switch-mobil-size - switch-h-padding);
        text-align: center;
        set-font-size(11px);
        transition: transform default-animation-duration ease;
    }
    .lib-actif{
        color: #fff;
        transform: scale(0, 0);
    }
    .lib-inactif{
        transform: scale(1, 1);
    }
    .switch-mobil{
        position: absolute;
        height: switch-mobil-size;
        width: switch-mobil-size;
        border-radius: 50%;
        background-color: $G3;
        top: 50%;
        left: switch-h-padding;
        transform: translate(0, -50%);
        transition: left default-animation-duration ease;
    }
    &.active{
        background-color: $A;
        .lib-actif{
            transform: scale(1, 1);
        }
        .lib-inactif{
            transform: scale(0, 0);
        }
        .switch-mobil{
            background-color: #fff;
            left: switch-width - switch-mobil-size - switch-h-padding;
        }
    }
    &.disabled{
        cursor: default;
    }
}