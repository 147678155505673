.fiche-list
	display flex

	ul
		width 100%
		list-style-type: none;
		padding: 0;

		li
			margin-bottom 25px

for name, $color in $ficheColors
	.chart-color{name}
		.barChart
			background-color $color
		.barChart-info
			color $color!important
