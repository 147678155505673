.error-page
	position absolute
	top 0
	bottom 0
	left 0
	right 0

	.leaflet-container
		top 0

	.container
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		z-index 1
		width 480px
		background-color white
		text-align center
		padding 80px 50px 50px 50px

		.illustration, .svg-img
			width 80px
			height 80px

		.illustration
			position absolute
			top 0
			left 50%
			transform translate(-50%, -50%)
			border-radius 50%
			box-shadow 0 0 10px rgba(black, .2)
			background-color white

		.svg-img
			background-image url("/assets/images/error-sad.svg")
			background-repeat no-repeat
			background-position center

		.titre
			font-family core-circus
			set-font-size(26px)
			letter-spacing -5px
			line-height 1.4em
			color $B

		.description
			margin 20px 0
			color $B
			line-height 1.4em

		.action
			display inline-block
			background-color $A
			border-radius 9999px
			color white
			padding 20px

	&.error-fixed
		position fixed

	&.error-418
		top top-nav-height
		addTrame(rgba($G4, .5), white)

	&.error-401
		.container
			.svg-img
				background-image url("/assets/images/error-login.svg")
