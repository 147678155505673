dots-count = 25

time = 2000ms
delay = 100ms
padding = 0.6rem

.helix-loader
    position absolute
    width 15rem
    height 15rem
    left 50%
    top 50%
    transform translateX(-50%) translateY(-50%) rotate(-90deg) scaleX(-1)

    .dot
        position absolute
        left 0
        width 0.5rem
        height 0.5rem
        background $A
        border-radius 1rem
        opacity 0
        animation-name dna_rotate
        animation-duration time
        animation-timing-function ease-in-out
        animation-iteration-count infinite

        for i in (1..dots-count)
            &:nth-child({i})
                top i * padding
                if (i % 2 == 0)
                    background $B
                    animation-delay ((i * delay) - (time * 2))
                else
                    animation-delay ((i * delay + time / 2) - (time * 2))

@keyframes dna_rotate
    0%
        opacity .5
        transform scale(1)
        left 40%
        z-index 0
    25%
        opacity 1
        transform scale(1.8)
    50%
        opacity .5
        left 60%
        z-index 1
        transform scale(1)
    75%
        opacity 0
        transform scale(0.5)
    100%
        opacity .5
        left 40%
        z-index 0
        transform scale(1)