.list-etablissements-container
	position absolute
	top 0
	bottom 0
	left 0
	right 0

#list-etablissements-resete-filter
	position absolute
	top top-nav-height + tabs-menu-height + selector-infos-height + fiche-summary-height - 48px
	right 10px

	&.benchmark
		right 50%
		transform translateX(50%)