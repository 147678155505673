.tabs-menu
    display: flex
    justify-content: center

    border-bottom: solid 2px $G4;
    height: tabs-menu-height;

    .item
        position relative
        cursor pointer
        height tabs-menu-height
        display: flex
        align-items center
        margin 0 20px
        padding 0 8px

        transition color default-effet-duration linear

        &:after
            position: absolute
            content: ""
            bottom: 0
            left: 50%
            right: 50%
            width: 0
            height: 2px
            background-color: $A

            transition left default-animation-duration ease, right default-animation-duration ease, width default-animation-duration ease

        &.active
            color: $A
            &:after
                left: 0
                right: 0
                width: 100%
    &.reverse
        border-bottom: none;
        border-top: solid 2px $G4;
        .item
            &:after
                top: 0
    &.space-between
        justify-content: space-between;
        .item
            &:first-child
                margin-left: 0;
                padding-left: 0;
            &:last-child
                margin-right: 0;
                padding-right: 0;