.offre-soins-container {
    display: flex;
    flex-direction: column;
    .offre-soins-list-container {
        flex: 1;
    }
    &.reverse {
        flex-direction: column-reverse;
        .offres-soins-toggle-display {
            width 100%
            max-width 9999px
            transition max-width 0ms linear default-animation-duration
        }
    }
}



.offres-soins-toggle-display {
    position: relative;
    height: carte-selection-load-height;
    background-color: $G5;
    color: $A;
    cursor: pointer;
    transition max-width 0ms linear 0ms
    margin: auto;

    .icon {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.offre-soins-list-container {
    background-color #fff
}

.cell-etablissement {
    text-transform capitalize
}